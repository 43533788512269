//                                                          src/style/global.sass
/////////////////////////////////////////////////////////////////////////////////
/////////////                                                    RESPONSIBILITIES
//  - referenced in src/index.tsx
//  - import typefaces and establish some global styles


/////////////////////////////////////////////////////////////////////////////////
/////////////                                                               SETUP

@use 'design'

@font-face
  font-family: 'Proxima Nova'
  src: url('./fonts/ProximaNovaRegular.otf')


@font-face
  font-family: 'Proxima Nova'
  src: url('./fonts/ProximaNovaSemibold.otf')
  font-weight: 600


@mixin normal-color-hover-color($normal-color, $hover-color)
  color: $normal-color
  &:hover
    color: $hover-color


/////////////////////////////////////////////////////////////////////////////////
/////////////                                                              STYLES

body
  font-family: 'Proxima Nova'
  margin: 0

ul
  margin-top: 0
  margin-bottom: 0
  padding-left: 0
  list-style: none

a
  text-decoration: none
  @include normal-color-hover-color(black, design.$light-red)
  
*:focus
  outline-style: dotted
  outline-color: design.$light-red
  outline-offset: 3px
  outline-width: thin

ul.sidemenu a.active
  @include normal-color-hover-color(design.$red, design.$light-red)

ul.mobilemenu a.active
  @include normal-color-hover-color(design.$white, design.$white)