//                                                   src/style/Layout.module.sass
/////////////////////////////////////////////////////////////////////////////////
/////////////                                                    RESPONSIBILITIES
//  - referenced in src/viewsCommon/Layout.tsx
//  - establish styles for page layout wrapper - menu placement


/////////////////////////////////////////////////////////////////////////////////
/////////////                                                               SETUP

@use 'design'

/////////////////////////////////////////////////////////////////////////////////
/////////////                                                              STYLES

#appLayout
  width: auto
  max-width: design.space(159.5)
  height: fit-content
  margin: design.space(7)

  display: grid
  grid-template-columns: design.space(27) auto
  grid-template-rows: auto

  @include design.mobile-media
    margin: design.space(2.5)
    grid-template-columns: 100%
    grid-template-rows: design.space(10.5) auto


.mobileMenuContainer
  width: 100%
  height: 0
  overflow: hidden
  background-color: design.$red
  transition-property: height, padding
  transition-duration: 0.3s
  text-align: center
  box-sizing: border-box
  & > ul > li
    line-height: 54px
    color: design.$light-red
    & > a
      color: design.$light-red
      &:hover
        color: design.$white

  &.open
    @include design.mobile-media
      height: design.space(64)
      padding: design.space(5)
  
  .lockedSubmenu
    margin: 0 auto design.space(3)
    width: design.space(36)
    border-top: 1px solid design.$light-red
    li
      line-height: design.space(5)
      & > a
        color: design.$light-red
        &:hover
          color: design.$white


.sideMenuContainer
  padding-right: design.space(6)
  box-sizing: border-box
  width: design.space(27)
  position: fixed
  @include design.mobile-media
    position: static
    width: unset
    padding-bottom: design.space(3.5)
    padding-right: design.space(0)

  #siteLogo
    width: 100%
    height: auto
    @include design.mobile-media
      width: auto
      height: 100%

  .sideMenuList
    margin-top: design.space(2)
    & > li
      line-height: 28px
    @include design.mobile-media
      display: none
    @include design.menu-link-type

    .submenuTitle
      @include design.menu-link-type
      background: none
      border: none
      padding: 0
      font-family: Proxima Nova

      &:hover
        color: design.$light-red
        cursor: pointer

    .submenuList
      height: design.space(0)
      overflow: hidden
      transition-property: height padding-top padding-bottom
      transition-duration: 0.3s
      padding-left: design.space(1)
      & > li
        line-height: 24px
      &.open
        height: design.space(9)
        padding: design.space(1)


  .mobileMenuControlContainer
    display: none
    width: design.space(3)
    margin: design.space(2.5)
    float: right
    cursor: pointer
    
    &:focus
      outline-offset: 1px

    @include design.mobile-media
      display: inline
      
      &.open div.bar1
        transition: transform 0.5s ease
        transform: rotate(45deg) translate(20%, 200%)
      &.open div.bar2
        transition: opacity 0.2s ease
        opacity: 0
      &.open div.bar3
        transition: transform 0.5s ease
        transform: rotate(-45deg) translate(24%, -260%)
      
      &:hover div
        transition: background-color 0.2s ease
        background-color: design.$light-red
      
      div
        width: 100%
        height: design.space(0.25)
        border-radius: design.space(1)
        margin-bottom: 5px
        background-color: design.$red
        &:last-child
          margin-bottom: 0

#pageContentContainer
  grid-column: 2/3
  @include design.mobile-media
    grid-column: unset