//                                                         src/style/_design.scss
/////////////////////////////////////////////////////////////////////////////////
/////////////                                                    RESPONSIBILITIES
//  - hold global design information
//    > Breakpoints
//    > Layout
//    > Colors
//    > Type Styles
//    > Animation


/////////////////////////////////////////////////////////////////////////////////
/////////////                                                         BREAKPOINTS

$mobile-breakpoint: 788px;

@mixin mobile-media {
  @media (max-width: $mobile-breakpoint) {
    @content;
  }
}
// usage:
// @use 'design'
// @include design.mobile-media
//    background-color: red


/////////////////////////////////////////////////////////////////////////////////
/////////////                                                              LAYOUT

$space-unit: 8px;

@function space($multiplier) {
  @return $multiplier * $space-unit;
}
// usage:
// margin-left: space(8)

/////////////////////////////////////////////////////////////////////////////////
/////////////                                                              COLORS

$red: #C3291C;
$light-red: #FFA8A8;
$ui-gray: #CCC;
$white: #FFF;


/////////////////////////////////////////////////////////////////////////////////
/////////////                                                         TYPE STYLES

@mixin copy-type {
  font-size: 13px;
  line-height: 21px;
}

@mixin copy-head-type {
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
}

@mixin menu-link-type {
  font-size: 15px;
  line-height: 24px;
}

@mixin art-meta-head-type {
  font-size: 13px;
  line-height: 18px;
  color: $white;
  @include mobile-media { margin-block-end: 0; }
}

@mixin art-meta-copy-type {
  @include copy-type();
  color: $white;
  @include mobile-media { margin-block-start: space(1.5); }
}

@mixin misc-link-styling {
  color: $red;
  &:hover {
    color: $light-red;
  }
}
// usage:
// @include art-meta-copy-type

/////////////////////////////////////////////////////////////////////////////////
/////////////                                                          ANIMATIONS

// mixin replicating keyframe animation for different vendors
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} { @content };
  @-moz-keyframes #{$name} { @content };
  @-ms-keyframes #{$name} { @content };
  @keyframes #{$name} { @content };
}
// usage:
// 

@include keyframes(fade-in) {
  0%    {opacity: 0;}
  100%  {opacity: 1;}
}